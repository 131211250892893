<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';

	export default {
		name: "BoStory",
		extends: Base,
		data() {
			return {
				Name: "BoStory",
				mrLevel: {},
				row2: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			submitFormCD(e, callback) {
				if (e && e.btnLoading()) return;
				this.row.type = 'submitCD'
				BOGen.apirest("/" + this.Name, this.row, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info')
						this.refreshData()
					}
				}, "POST")
			},
			submitFormBL(e, callback) {
				if (e && e.btnLoading()) return;
				this.row2.type = 'submitBL'
				BOGen.apirest("/" + this.Name, this.row2, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info1')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info1')
						this.refreshData()
					}
				}, "POST")
			},
		},
		watch: {
			// '$route.query'() {
			// 	this.refreshData()
			// },
			'filter.level'() {
				this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row">

			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">Our Story - Cape Discovery</h5>
						</div>
						<hr>
						<VForm @resp="submitFormCD" method="post">
							<div class="row">
								<div class="col-md-12 info"></div>
								<div class="col-md-7">
									<div class="form-group">
										<!-- <label for="">The Story</label> -->
										<BoField :label="'Story Title (ID)'" v-model="row.sc_title_id"></BoField>
										<BoField :label="'Story Description (ID)'" mandatory>
											<CKEditor name="pp_content1" class="text-editor form-control" rows="15"
												v-model="row.sc_desc_id"></CKEditor>
										</BoField>
										<BoField :label="'Story Description (EN)'" v-model="row.sc_title_en" mandatory>
										</BoField>
										<BoField :label="'Story Description (EN)'" mandatory>
											<CKEditor name="pp_content2" class="text-editor form-control" rows="15"
												v-model="row.sc_desc_en"></CKEditor>
										</BoField>
									</div>
								</div>
								<div class="col-md-5">
									<!-- <BoField name="sc_link_instagram" v-model="row.sc_link_instagram"></BoField> -->
									<!-- <BoField :label="'Logo Image'">
										<Uploader name="sc_cd_image" type="img_story_logo" uploadType="cropping"
											:param="{thumbnail:true}" v-model="row.sc_image1">
										</Uploader>
									</BoField> -->
									<label for="">&nbsp;</label>
									<Checkbox class="mt-4" v-model="row.sc_show_image2">{{fields.sc_show_image2}}
									</Checkbox>
									<Uploader v-if="row.sc_show_image2" name="sc_image2" type="img_story"
										uploadType="cropping" :param="{thumbnail:true}" v-model="row.sc_image2">
									</Uploader>
								</div>
								<div class="col-12">
									<div class="card">
										<div class="card-body text-right">
											<button type="submit" v-if="page.moduleRole('Update')"
												class="btn btn-success btn-loading">Submit Changes</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">Our Story - Bellisimo</h5>
						</div>
						<hr>
						<VForm @resp="submitFormBL" method="post">
							<div class="row">
								<div class="col-md-12 info1"></div>
								<div class="col-md-7">
									<div class="form-group">
										<!-- <label for="">The Story</label> -->
										<BoField :label="'Story Title (ID)'" v-model="row2.sc_title_id" mandatory>
										</BoField>
										<BoField :label="'Story Description (ID)'" mandatory>
											<CKEditor name="pp_content3" class="text-editor form-control" rows="15"
												v-model="row2.sc_desc_id"></CKEditor>
										</BoField>
										<BoField :label="'Story Title (EN)'" v-model="row2.sc_title_en" mandatory>
										</BoField>
										<BoField :label="'Story Description (EN)'" mandatory>
											<CKEditor name="pp_content4" class="text-editor form-control" rows="15"
												v-model="row2.sc_desc_en"></CKEditor>
										</BoField>
									</div>
								</div>
								<div class="col-md-5">
									<label for="">&nbsp;</label>
									<!-- <BoField :label="'Logo Image'">
										<Uploader name="sc_cd_image" type="img_story_logo" uploadType="cropping"
											:param="{thumbnail:true}" v-model="row2.sc_image1">
										</Uploader>
									</BoField> -->
									<Checkbox class="mt-4" v-model="row2.sc_show_image2">{{fields.sc_show_image2}}
									</Checkbox>
									<Uploader v-if="row2.sc_show_image2" name="sc_image2" type="img_story"
										uploadType="cropping" :param="{thumbnail:true}" v-model="row2.sc_image2">
									</Uploader>
								</div>

								<div class="col-12">
									<div class="card">
										<div class="card-body text-right">
											<button type="submit" v-if="page.moduleRole('Update')"
												class="btn btn-success btn-loading">Submit Changes</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>